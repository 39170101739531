const TasksIcon = ({icon}) => {

    switch (icon) {
        case 'tg':
            return (
                <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M24.6442 26.8868L24.6451 26.8845L24.6683 26.8265L28.6667 6.66774V6.60226C28.6667 6.09999 28.4811 5.66151 28.0772 5.3985C27.7238 5.16828 27.3174 5.15189 27.0325 5.17352C26.7311 5.19639 26.447 5.27161 26.2502 5.33422C26.149 5.36644 26.0643 5.39747 26.0038 5.42095C25.9734 5.43273 25.9487 5.44273 25.9308 5.45018L25.9132 5.45759L3.62011 14.2028L3.61397 14.205C3.60193 14.2094 3.58601 14.2154 3.56678 14.2228C3.52844 14.2378 3.47626 14.2591 3.41495 14.2869C3.29457 14.3415 3.12826 14.4257 2.95959 14.5412C2.67312 14.7373 2.12763 15.2018 2.21974 15.9381C2.29604 16.5479 2.71644 16.9353 2.99987 17.1358C3.1518 17.2433 3.29715 17.3208 3.40359 17.3715C3.45752 17.3972 3.50333 17.4168 3.53744 17.4307C3.55455 17.4377 3.56885 17.4433 3.57992 17.4475L3.59408 17.4528L3.60314 17.4561L7.50346 18.7692C7.4903 19.0139 7.51457 19.2634 7.57946 19.5096L9.53318 26.9208C9.76482 27.7996 10.5598 28.4117 11.4686 28.411C12.2831 28.4104 13.0056 27.9176 13.3125 27.1824L16.3627 23.921L21.6014 27.9373L21.6758 27.9698C22.1518 28.1775 22.5965 28.2433 23.003 28.1877C23.409 28.1323 23.7317 27.962 23.974 27.7681C24.2125 27.5773 24.3759 27.362 24.4787 27.1999C24.5309 27.1175 24.5699 27.0451 24.5969 26.9906C24.6104 26.9633 24.6211 26.9401 24.6291 26.9221L24.6392 26.8989L24.6427 26.8903L24.6442 26.8868ZM9.51333 18.9998C9.46893 18.8313 9.53863 18.6535 9.68568 18.5601L22.915 10.1589C22.915 10.1589 23.6932 9.68653 23.6653 10.1589C23.6653 10.1589 23.8042 10.2424 23.3873 10.6313C22.9918 11.0008 13.9583 19.7222 13.0443 20.6047C12.9915 20.6556 12.96 20.7141 12.9415 20.785L11.467 26.411L9.51333 18.9998Z"
                          fill="#F1F1F1"/>
                </svg>
            )
        case 'twitter':
            return (
                <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M0.0780487 0.5L12.4341 18.1521L0 32.5H2.8L13.6829 19.9345L22.4781 32.5H32L18.9512 13.8577L30.522 0.5H27.7268L17.7024 12.07L9.60488 0.5H0.0780487ZM4.19512 2.69935L4.89253 3.69565L23.5122 30.2954L4.19512 2.69935Z"
                        fill="#F1F1F1"/>
                </svg>

            )
        case 'instagram':
            return (
                <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M16 12.1666C13.6068 12.1666 11.6667 14.1067 11.6667 16.4999C11.6667 18.8931 13.6068 20.8332 16 20.8332C18.3932 20.8332 20.3333 18.8931 20.3333 16.4999C20.3333 14.1067 18.3932 12.1666 16 12.1666Z"
                        fill="#F1F1F1"/>
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M9.02605 4.60852C13.6235 4.09469 18.3765 4.09469 22.974 4.60852C25.5052 4.89142 27.5468 6.88579 27.8439 9.42586C28.3936 14.1259 28.3936 18.8739 27.8439 23.5739C27.5468 26.114 25.5052 28.1084 22.974 28.3913C18.3765 28.9051 13.6235 28.9051 9.02605 28.3913C6.49485 28.1084 4.45326 26.114 4.15618 23.5739C3.60647 18.8739 3.60647 14.1259 4.15618 9.42586C4.45326 6.88579 6.49485 4.89142 9.02605 4.60852ZM22.6667 8.4999C21.9303 8.4999 21.3333 9.09685 21.3333 9.83323C21.3333 10.5696 21.9303 11.1666 22.6667 11.1666C23.403 11.1666 24 10.5696 24 9.83323C24 9.09685 23.403 8.4999 22.6667 8.4999ZM9.66667 16.4999C9.66667 13.0021 12.5022 10.1666 16 10.1666C19.4978 10.1666 22.3333 13.0021 22.3333 16.4999C22.3333 19.9977 19.4978 22.8332 16 22.8332C12.5022 22.8332 9.66667 19.9977 9.66667 16.4999Z"
                          fill="#F1F1F1"/>
                </svg>

            )
        case 'person':
            return (
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M14.6667 5C11.9052 5 9.66666 7.23858 9.66666 10C9.66666 12.7614 11.9052 15 14.6667 15C17.4281 15 19.6667 12.7614 19.6667 10C19.6667 7.23858 17.4281 5 14.6667 5Z"
                        fill="#CCCCCC"/>
                    <path
                        d="M9.33333 17.6667C6.5719 17.6667 4.33333 19.9052 4.33333 22.6667V24.251C4.33333 25.2554 5.06117 26.1117 6.05236 26.2735C11.7575 27.2049 17.5759 27.2049 23.281 26.2735C24.2722 26.1117 25 25.2554 25 24.251V22.6667C25 19.9052 22.7614 17.6667 20 17.6667H19.5455C19.2995 17.6667 19.0551 17.7056 18.8212 17.7819L17.6672 18.1587C15.7175 18.7954 13.6158 18.7954 11.6661 18.1587L10.5121 17.7819C10.2783 17.7056 10.0338 17.6667 9.78782 17.6667H9.33333Z"
                        fill="#CCCCCC"/>
                    <path
                        d="M26 8.33333C26.5523 8.33333 27 8.78105 27 9.33333V11.6667H29.3333C29.8856 11.6667 30.3333 12.1144 30.3333 12.6667C30.3333 13.219 29.8856 13.6667 29.3333 13.6667H27V16C27 16.5523 26.5523 17 26 17C25.4477 17 25 16.5523 25 16V13.6667H22.6667C22.1144 13.6667 21.6667 13.219 21.6667 12.6667C21.6667 12.1144 22.1144 11.6667 22.6667 11.6667H25V9.33333C25 8.78105 25.4477 8.33333 26 8.33333Z"
                        fill="#CCCCCC"/>
                </svg>

            )
        case 'reels':
            return (
                <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M9.28852 8.25186C12.4256 7.97137 15.5824 7.99586 18.7147 8.32496L19.525 8.4101C21.0716 8.5726 22.3163 9.74569 22.5752 11.2725L26.9464 8.95015C27.2426 8.79277 27.598 8.79439 27.8928 8.95447C28.1876 9.11455 28.3825 9.41176 28.4117 9.74592L28.4449 10.1244C28.8168 14.3666 28.8168 18.6332 28.4449 22.8755L28.4117 23.2539C28.3825 23.5881 28.1876 23.8853 27.8928 24.0454C27.598 24.2054 27.2426 24.2071 26.9464 24.0497L22.5752 21.7273C22.3163 23.2542 21.0716 24.4273 19.525 24.5898L18.7147 24.6749C15.5824 25.004 12.4256 25.0285 9.28851 24.748L7.17947 24.5594C5.59027 24.4173 4.30624 23.2033 4.07543 21.6245C3.5786 18.2262 3.5786 14.7737 4.07543 11.3754C4.30624 9.7966 5.59027 8.58253 7.17947 8.44043L9.28852 8.25186ZM22.8219 19.6554C22.9289 19.6722 23.0349 19.7068 23.1359 19.7605L26.5426 21.5704C26.7843 18.1944 26.7843 14.8054 26.5426 11.4294L23.1359 13.2394C23.0349 13.293 22.9289 13.3276 22.8219 13.3444C23.0088 15.4439 23.0088 17.5559 22.8219 19.6554Z"
                          fill="#CCCCCC"/>
                </svg>

            )
    }
};

export default TasksIcon;