import React, {useEffect} from 'react';
import './DailyRewardPage.css';
import confetti from 'canvas-confetti';

const DailyRewardPage = () => {

    useEffect(() => {
        confetti({
            particleCount: 100,
            spread: 70,
            origin: {y: 0.6}
        });
    }, []);

    const collectDailyReward = () => {
        window.location.href = '/';
    };

    return (
        <div className="onboarding-page">
            <svg className="background-square"
                 width="295" height="282" viewBox="0 0 295 282" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_67_3773)">
                    <rect width="295" height="282" rx="13" fill="#181A20"/>
                    <g filter="url(#filter0_f_67_3773)">
                        <circle cx="147.5" cy="0.5" r="124.5" fill="#31BDF9"/>
                    </g>
                </g>
                <rect x="0.5" y="0.5" width="294" height="281" rx="12.5" stroke="#35383F"/>
                <defs>
                    <filter id="filter0_f_67_3773" x="-71" y="-218" width="437" height="437"
                            filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                        <feGaussianBlur stdDeviation="47" result="effect1_foregroundBlur_67_3773"/>
                    </filter>
                    <clipPath id="clip0_67_3773">
                        <rect width="295" height="282" rx="13" fill="white"/>
                    </clipPath>
                </defs>
            </svg>

            <h1 className={"votes"}>100<br/>VOTES</h1>
            <button className="onboarding-page-button" onClick={collectDailyReward}>Continue</button>
        </div>
    );
};

export default DailyRewardPage;
