import './LeaderboardPage.css';
import {useEffect, useState} from "react";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import * as client from "../../../utils/client";
import {useTelegram} from "../../../hooks/useTelegram";

const LeaderboardPage = ({user}) => {
    const [users, setUsers] = useState([]);
    const [friends, setFriends] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filter, setFilter] = useState('red');
    const {telegramUser} = useTelegram();

    useEffect(() => {
        client.getFriendsLeaderBoard(telegramUser.id).then(friendsJson => {
            setFriends(friendsJson.result);
            client.getUsers().then(usersJson => {
                setUsers(usersJson.result);
                setLoading(false);
            });
        });
    }, [telegramUser.id]);

    if (loading) {
        return <LoadingSpinner/>;
    }

    let filteredUsers;
    if (filter === 'friends') {
        filteredUsers = friends;
    } else {
        filteredUsers = Array.from(users).filter(user => user.selected_team === filter);
    }

    const sortedUsers = Array.from(filteredUsers).sort((a, b) => b.points - a.points);

    return (
        <div className="enable-scroll-content page-content">
            <div className="filter-buttons">
                <button
                    className={`filter-button ${filter === "friends" ? "active" : ""} friends`}
                    onClick={() => setFilter("friends")}
                >
                    Friends
                </button>
                <button
                    className={`filter-button ${filter === "red" ? "active" : ""} red`}
                    onClick={() => setFilter("red")}
                >
                    Republicans
                </button>
                <button
                    className={`filter-button ${filter === "blue" ? "active" : ""} blue`}
                    onClick={() => setFilter("blue")}
                >
                    Democrats
                </button>
            </div>
            <div className="leaderboard-list">
                {sortedUsers.map((user, index) => (
                    <>
                        <div key={user.tg_id} className="leaderboard-item">
                            <div className="leaderboard-rank">
                                {index === 0 && <img src="/GoldMedal.png" alt="Gold Medal" className="medal-img"/>}
                                {index === 1 && <img src="/SilverMedal.png" alt="Silver Medal" className="medal-img"/>}
                                {index === 2 && <img src="/BronzeMedal.png" alt="Bronze Medal" className="medal-img"/>}
                                {index > 2 && <div className="rank-number">{index + 1}</div>}
                            </div>
                            <div className="leaderboard-details">
                                <div className={"leaderboard-user"}>
                                    <div className={"leaderboard-avatar"}>
                                        {user.first_name[0]}
                                    </div>
                                    <div className="leaderboard-name">{user.first_name} {user.last_name}</div>
                                </div>
                                <div className="leaderboard-points">{user.points}</div>
                            </div>
                        </div>
                        <hr className="separator"/>
                    </>
                ))}
            </div>
        </div>
    );
};

export default LeaderboardPage;
