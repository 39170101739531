import './OnlyMobileDevicePage.css';
import Starfield from "react-starfield";
import React from "react";
import '@fortawesome/fontawesome-free/css/all.min.css';


const OnlyMobileDevicePage = () => {
    return (
        <div>
            <Starfield
                starCount={1000}
                starColor={[255, 255, 255]}
                speedFactor={0.05}
                backgroundColor="black"
            />
            <div className='only-mobile-device-page title'>
                Try exactly the same but from mobile
                <div className='only-mobile-device-page subtitle'>
                    Desktop version is under construction
                    <div className="hammers-container">
                        <i className="fas fa-hammer hammer-left"></i>
                        <i className="fas fa-hammer hammer-right"></i>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OnlyMobileDevicePage;
