import {useEffect, useState} from 'react';
import './HomePage.css';
import {useTelegram} from "../../../hooks/useTelegram";
import {useHapticFeedback} from "@vkruglikov/react-telegram-web-app";
import ProgressBar from "../../ProgressBar/ProgressBar";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import President from "../../President/President";
import * as client from "../../../utils/client";

const BUTTON_STATUS = [
    "CLAIM",
    "FARMING",
    "COLLECT"
];

const HomePage = ({user, setUser}) => {
    const {tg} = useTelegram();
    const [impactOccurred] = useHapticFeedback();
    const [buttonStatus, setButtonStatus] = useState(null);
    const [loading, setLoading] = useState(true);
    const [timeLeft, setTimeLeft] = useState(0);
    const [canClaim, setCanClaim] = useState(true);
    const [voteStartTime, setVoteStartTime] = useState(null);

    useEffect(() => {
        setVoteStartTime(user.vote_start_time
            ? new Date(user.vote_start_time)
            : null);
    }, [user.vote_start_time]);

    useEffect(() => {
        tg.ready();
        setLoading(false);
    }, [tg]);

    useEffect(() => {
        const checkClaimStatus = () => {
            const currentTime = Date.now();

            if (voteStartTime) {
                const elapsedTime = currentTime - voteStartTime.getTime();
                const eightHours = 8 * 60 * 60 * 1000; // 8 hours in milliseconds

                if (elapsedTime >= eightHours) {
                    setButtonStatus(BUTTON_STATUS[2]);
                    setCanClaim(true);
                    setTimeLeft(0);
                } else {
                    setButtonStatus(BUTTON_STATUS[1]);
                    setCanClaim(false);
                    setTimeLeft(eightHours - elapsedTime);
                }
            } else {
                setButtonStatus(BUTTON_STATUS[0]);
                setCanClaim(true);
                setTimeLeft(0);
            }
        };

        checkClaimStatus();
        const intervalId = setInterval(checkClaimStatus, 1000);

        return () => clearInterval(intervalId);
    }, [voteStartTime]);


    const handleCollect = () => {
        client.stopVote(user.tg_id)
            .then(ignored => {
                setVoteStartTime(null);
                setButtonStatus('CLAIM');
                setCanClaim(true);
            });

        setUser({
            ...user,
            points: parseInt(user?.points || 0) + 100,
            vote_start_time: null
        });

        impactOccurred('soft');
    }

    const handleClaim = () => {
        if (canClaim) {
            client.startVote(user.tg_id)
                .then(voteStartTimeJson => {
                    setVoteStartTime(new Date(voteStartTimeJson));
                    setCanClaim(false);
                });

            setUser({
                ...user,
                vote_start_time: Date.now()
            });

            impactOccurred('soft');
        }
    };

    const formatTimeLeft = (time) => {
        const hours = Math.floor(time / (1000 * 60 * 60));
        const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((time % (1000 * 60)) / 1000);
        return `${hours}h ${minutes}m ${seconds}s`;
    };

    if (loading) {
        return <LoadingSpinner/>
    }

    return (
        <div className="page-content">
            <div className="points-main">
                <div className="background-points" style={{position: "absolute", zIndex: -2, width: "100%"}}>
                    <svg viewBox="0 0 375 234" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_202_1865)">
                            <path
                                d="M187.5 -156.177L459 45.8231L225.354 194.016C204.656 207.144 178.279 207.298 157.429 194.413L-83 45.823L187.5 -156.177Z"
                                fill="#1F222A"></path>
                            <path
                                d="M-82.1659 60.0738L180.01 -172.84L458.154 60.0902L225.087 207.917C204.55 220.943 178.379 221.095 157.692 208.31L-82.1659 60.0738Z"
                                stroke="#35383F"></path>
                            <g filter="url(#filter0_f_202_1865)">
                                <circle cx="184.709" cy="-41.177" r="124.5" fill="#31BDF9"></circle>
                            </g>
                            <g filter="url(#filter1_f_202_1865)">
                                <circle cx="184.709" cy="-41.177" r="124.5" fill="#31BDF9"></circle>
                            </g>
                        </g>
                        <defs>
                            <filter id="filter0_f_202_1865" x="-33.7914" y="-259.677" width="437" height="437"
                                    filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix"
                                         result="shape"></feBlend>
                                <feGaussianBlur stdDeviation="47"
                                                result="effect1_foregroundBlur_202_1865"></feGaussianBlur>
                            </filter>
                            <filter id="filter1_f_202_1865" x="-33.7914" y="-259.677" width="437" height="437"
                                    filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix"
                                         result="shape"></feBlend>
                                <feGaussianBlur stdDeviation="47"
                                                result="effect1_foregroundBlur_202_1865"></feGaussianBlur>
                            </filter>
                            <clipPath id="clip0_202_1865">
                                <rect width="375" height="234" fill="white"></rect>
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                <ProgressBar user={user}/>
                <h1 className="points-container">
                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="34" viewBox="0 0 19 34" fill="none">
                        <path d="M0.576 0H5.904L9.36 26.064H9.456L12.912 0H17.76L12.672 33.6H5.664L0.576 0Z"
                              fill="#F1F1F1"/>
                        <path d="M0 13.44H18.336V17.28H0V13.44Z" fill="#F1F1F1"/>
                    </svg>
                    {user.points}</h1>
            </div>
            <div className="bottom-home">
                <div className="president-container">
                    <div className="stars-container left">
                        <img src="/stars_right.png" alt="Star" className="star-image"/>
                    </div>
                    <div className="president-content">
                        <President user={user}/>
                    </div>
                    <div className="stars-container right">
                        <img src="/stars_left.png" alt="Star" className="star-image"/>
                    </div>
                </div>
                <div className="button-container">
                    {buttonStatus !== BUTTON_STATUS[2]
                        ? (<button className={`claim-button ${canClaim ? '' : 'claimed'} ${user.selected_team}-button`}
                                   onClick={handleClaim}
                                   disabled={!canClaim}>
                            {canClaim ? 'VOTE' : `Reactivate vote in ${formatTimeLeft(timeLeft)}`}
                        </button>)
                        : <button className={`claim-button ${canClaim ? '' : 'claimed'} ${user.selected_team}-button`}
                                  onClick={handleCollect}>
                            COLLECT
                        </button>}
                </div>
            </div>
        </div>
    );
};

export default HomePage;
