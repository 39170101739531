import React, {useEffect, useState} from 'react';
import './ProgressBar.css';
import * as client from "../../utils/client";

const ProgressBar = ({user}) => {
    const [redTeamPoints, setRedTeamPoints] = useState(0);
    const [blueTeamPoints, setBlueTeamPoints] = useState(0);
    const [voteExpirationDate, setVoteExpirationDate] = useState(null);

    useEffect(() => {
        client.getTeamVotes().then(votes => {
            setRedTeamPoints(votes.counts['redVotes']);
            setBlueTeamPoints(votes.counts['blueVotes']);
        });
    }, []);

    useEffect(() => {
        const electionExpirationDate = new Date('2024-11-05T00:00:00');

        const updateCountdown = () => {
            const currentDate = new Date();
            const timeDifference = electionExpirationDate - currentDate;

            if (timeDifference <= 0) {
                setVoteExpirationDate('The target date has passed');
                clearInterval(intervalId);
                return;
            }

            const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
            const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

            setVoteExpirationDate(`${days}d ${hours}h ${minutes}m ${seconds}s`);
        };

        const intervalId = setInterval(updateCountdown, 1000);

        return () => clearInterval(intervalId);
    }, []);

    const getPercentage = (red, blue, user) => {
        const total = red + blue;
        if (total <= 0) return 50.00;
        let percentage;
        if (user.selected_team === 'blue') {
            percentage = (blue / total) * 100;
        } else {
            percentage = (red / total) * 100;
        }
        return parseFloat(percentage.toFixed(2));
    };

    const percentage = getPercentage(redTeamPoints, blueTeamPoints, user);

    return (
        <div className="progress-bar-container">
            <div className='vote-expiration-date-container'>
                Elections will end: {voteExpirationDate}
            </div>
            <div className="progress-bar">
                <div className={`bar ${user.selected_team}`} style={{
                    width: `${percentage}%`
                }}>
                    YOUR TEAM ({percentage}%)
                </div>
                <div className={`opponent ${user.selected_team === 'blue' ? 'red' : 'blue'}`}>
                </div>
            </div>
        </div>
    );
};

export default ProgressBar;
