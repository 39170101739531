import './FriendsPage.css';
import {useEffect, useState} from "react";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import * as client from "../../../utils/client";
import {useTelegram} from "../../../hooks/useTelegram";

const FriendsPage = () => {
    const [friends, setFriends] = useState([]);
    const [loading, setLoading] = useState(true);
    const {telegramUser} = useTelegram();

    useEffect(() => {
        client.getFriendsLeaderBoard(telegramUser.id).then(friendsJson => {
            setFriends(friendsJson.result);
            setLoading(false);
        });
    }, [telegramUser.id]);

    if (loading) {
        return <LoadingSpinner/>;
    }

    const handleInviteClick = () => {
        // Построение deeplink URL для приглашения
        const message = encodeURIComponent(`Join me on Votes and let's earn together! Use my invite link to join the fun. 🇺🇸🔥 https://t.me/votes_io_bot?start=${telegramUser.id}`);
        const telegramUrl = `https://t.me/share/url?url=${message}`;

        // Открытие ссылки в новой вкладке
        window.open(telegramUrl, '_blank');
    };
    return (
        <div className="page-content enable-scroll-content">
            <div className="friends-page-header">
                <div className="header-text">
                    <div className="header-title">INVITE FRIENDS</div>
                </div>
                <button className="add-button" onClick={handleInviteClick}>+ Friends</button>
            </div>
            <div className="header-subtitle">Every friend will boost your party and your votes</div>
            <div className="friends-list">
                {Array.from(friends).map(friend => (
                    <div key={friend.id} className="friend-box">
                        <div className={'friend-avatar'}>
                            {friend.first_name[0]}
                        </div>
                        <div className="friend-details">
                            <div className="friend-name">{friend.first_name + " " + friend.last_name}</div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FriendsPage;
