import React from 'react';
import './President.css'

const President = ({user}) => {
    if (user.selected_team === 'red')
        return (
            <div className={'president'}>
                <img src={"TrumpHome.png"} alt={"Trump"}/>
            </div>
        );
    return (
        <div className={'president'}>
            <img src={"HarrisHome.png"} alt={"Harris"}/>
        </div>
    );

};

export default President;