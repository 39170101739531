import './App.css';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {useEffect, useState} from "react";
import HomePage from "./components/page/HomePage/HomePage";
import FriendsPage from "./components/page/FriendsPage/FriendsPage";
import TasksPage from "./components/page/TasksPage/TasksPage";
import LeaderboardPage from "./components/page/LeaderboardPage/LeaderboardPage";
import LoadingSpinner from "./components/LoadingSpinner/LoadingSpinner";
import {useTelegram} from "./hooks/useTelegram";
import * as client from "./utils/client";
import PickTeamPage from "./components/page/onboarding/PickTeamPage/PickTeamPage";
import DailyRewardPage from "./components/page/onboarding/DailyRewardPage/DailyRewardPage";
import NavBar from "./components/NavBar/NavBar";
import "@fontsource/poppins";
import "@fontsource/bebas-neue";
import OnlyMobileDevicePage from "./components/page/OnlyMobileDevicePage/OnlyMobileDevicePage";

function App() {
    const {telegramUser, tg} = useTelegram();
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isMobilePlatform, setIsMobilePlatform] = useState(false);
    const [showDailyRewardPage, setShowDailyRewardPage] = useState(false);

    useEffect(() => {
        tg.ready();
        tg.expand();
        tg.disableVerticalSwipes();
        setIsMobilePlatform(tg.platform === "android" || tg.platform === "ios" || tg.platform === "mobile");
    }, [tg]);

    useEffect(() => {
        if (telegramUser && !user) {
            client.getUserByTelegramId(telegramUser.id)
                .then(response => {
                    setUser(response.user);
                    setShowDailyRewardPage(response.user.last_reward);
                })
                .catch(error => {
                    console.error("Ошибка при получении пользователя:", error.message);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [telegramUser, user]);

    if (!isMobilePlatform) {
        return <OnlyMobileDevicePage/>;
    }

    if (showDailyRewardPage) {
        return <DailyRewardPage/>;
    }

    if (loading) {
        return <LoadingSpinner/>;
    }

    return (
        <Router>
            <div className="app-container">
                <div className="content">
                    <Routes>
                        <Route path='/'
                               element={user && user.selected_team ? <HomePage user={user} setUser={setUser}/> :
                                   <PickTeamPage/>}
                        />
                        <Route
                            path='/friends'
                            element={user && user.selected_team ? <FriendsPage user={user}/> : <PickTeamPage/>}
                        />
                        <Route
                            path='/board'
                            element={user && user.selected_team ? <LeaderboardPage user={user}/> : <PickTeamPage/>}
                        />
                        <Route
                            path='/tasks'
                            element={user && user.selected_team ? <TasksPage user={user} setUser={setUser}/> :
                                <PickTeamPage/>}
                        />
                        <Route
                            path='/daily-reward'
                            element={user && user.selected_team ? <DailyRewardPage/> : <PickTeamPage/>}
                        />
                    </Routes>
                </div>
                {user && user.selected_team && !window.location.pathname.includes('/daily-reward') && <NavBar/>}
            </div>
        </Router>
    );
}

export default App;
