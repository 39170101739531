import './TasksPage.css';
import * as client from "../../../utils/client";
import {useEffect, useState} from "react";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import {useHapticFeedback} from "@vkruglikov/react-telegram-web-app";
import TasksIcon from "../../TasksIcon/TasksIcon";
import {useTelegram} from "../../../hooks/useTelegram";
import confetti from "canvas-confetti";

const TasksPage = ({user, setUser}) => {
    const [tasks, setTasks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [impactOccurred] = useHapticFeedback();
    const [loadingTasks, setLoadingTasks] = useState({});
    const [tasksIcons, setTasksIcons] = useState(['tg', 'twitter', 'instagram', 'person', 'reels']);
    const {tg, telegramUser} = useTelegram();

    useEffect(() => {
        client.getTasks(telegramUser.id).then(tasksJson => {
            setTasks(tasksJson.result);
            setLoading(false);
        });
    }, [telegramUser.id]);

    const handleSubscribeClick = (taskId, taskUrl) => {
        setLoadingTasks(prevState => ({...prevState, [taskId]: true}));

        if (taskUrl.startsWith("https://t.me/")) {
            tg.openTelegramLink(taskUrl);
        } else {
            tg.openLink(taskUrl);
        }

        client.taskExecutingCheck(taskId, telegramUser.id).then(taskExecutingResult => {
            if (taskExecutingResult === true) {
                setLoadingTasks(prevState => ({...prevState, [taskId]: 'collect'}));
            } else {
                setLoadingTasks(prevState => ({...prevState, [taskId]: false}));
            }
        });
    };

    const collectVotes = (taskId, taskAmount) => {
        client.collectVotes(taskId, telegramUser.id)
            .then(taskAmountJson => {
                if (taskAmountJson.result === 1 || taskAmountJson.result === true) {
                    setUser({
                        ...user,
                        points: parseInt(user?.points || 0) + parseInt(taskAmount)
                    });
                    confetti({
                        particleCount: 100,
                        spread: 70,
                        origin: {y: 0.6}
                    });
                    impactOccurred('soft');
                    setTasks(prevTasks =>
                        prevTasks.map(task =>
                            task.id === taskId ? {...task, completed_at: true} : task
                        )
                    );
                }
            })
            .catch(error => {
                console.error("Failed to collect votes:", error);
            });
    }

    if (loading) {
        return <LoadingSpinner/>;
    }

    return (
        <div className="enable-scroll-content page-content">
            <div className="task-page-header">TASKS</div>
            {Array.from(tasks).map((task, index) => (
                <div key={task.id} className="task-box">
                    <div className="task-left-side">
                        <div className="image-container">
                            <TasksIcon icon={tasksIcons[index]}/>
                        </div>
                        <div className="task-info">
                            <div className="task-text">
                                {task.title}
                            </div>
                            <div className="task-value">
                                + {task.reward_level} V
                            </div>
                        </div>
                    </div>

                    {task.completed_at
                        ? (<div className="task-done">
                            ✔
                        </div>)
                        : loadingTasks[task.id] === 'collect'
                            ? <button className="collect-task-amount-button"
                                      onClick={() => collectVotes(task.id, task.reward_level)}>
                                Collect
                            </button>
                            : loadingTasks[task.id]
                                ? <button className="collect-task-amount-button" disabled={true}>
                                    <div className="small-spinner"/>
                                </button>
                                : <button className="collect-task-amount-button"
                                          onClick={() => handleSubscribeClick(task.id, task.description)}>
                                    Sign
                                </button>
                    }
                </div>
            ))}
        </div>
    );
};

export default TasksPage;
