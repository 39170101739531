import './NavBar.css';
import {NavLink} from "react-router-dom";
import {useHapticFeedback} from "@vkruglikov/react-telegram-web-app";

const NavBarRouter = [
    {
        title: 'Home',
        path: '/',
        icon: (
            <svg className="nav-icon"
                 viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M13.7958 4.13612C12.8133 3.1215 11.1861 3.1215 10.2037 4.13611L5.40521 9.09185C5.12963 9.37646 4.94444 9.73627 4.87299 10.1259C4.29023 13.3039 4.24721 16.5574 4.74574 19.7496L4.92225 20.8798C4.978 21.2368 5.28546 21.5 5.64677 21.5H8.99973C9.27587 21.5 9.49973 21.2761 9.49973 21V14H14.4997V21C14.4997 21.2761 14.7236 21.5 14.9997 21.5H18.3526C18.7139 21.5 19.0214 21.2368 19.0772 20.8798L19.2537 19.7496C19.7522 16.5574 19.7092 13.3039 19.1264 10.1259C19.055 9.73627 18.8698 9.37646 18.5942 9.09185L13.7958 4.13612Z"
                    fill="#CCCCCC"/>
            </svg>
        ),
        cName: 'nav-text'
    },
    {
        title: 'Friends',
        path: '/friends',
        icon: (
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M21.4303 12.31C21.2682 11.988 21.0014 11.7768 20.6584 11.6987C19.9998 11.5118 18.2375 10.9867 17.8037 10.7505C17.6387 10.6234 16.8159 9.67501 16.1077 8.82161C16.1042 8.81723 16.1004 8.81307 16.0965 8.80891C15.601 8.27312 14.7634 7.92688 13.9625 7.92688C13.2527 7.92688 12.5755 8.18646 12.0045 8.67741C11.9991 8.68201 11.994 8.68682 11.9888 8.69186L9.12549 11.514C8.80112 11.8149 8.52204 12.3923 8.48926 12.8322L8.32204 15.0732C8.28265 15.6022 8.67814 16.0894 9.20385 16.1595L9.88149 16.2498C9.92438 16.2555 9.96794 16.2584 10.0111 16.2584C10.4985 16.2584 10.9194 15.8951 10.9896 15.4133C10.9899 15.4122 10.9899 15.4111 10.9901 15.41L11.2002 13.8583C11.2022 13.8443 11.203 13.8303 11.203 13.8163C11.203 13.6867 11.2785 13.3002 11.9772 12.5133C12.0104 12.4759 12.0439 12.4389 12.077 12.4028L11.1995 16.4667C11.1949 16.4884 11.1925 16.5107 11.1925 16.5331C11.1925 16.9596 11.3779 17.572 11.6147 17.9268L16.4193 25.129C16.6787 25.5179 17.2482 25.8226 17.7157 25.8226H18.4616C18.7378 25.8226 18.9797 25.7077 19.125 25.5072C19.2703 25.3067 19.3045 25.041 19.2185 24.7786C19.2134 24.7633 19.2073 24.7482 19.1999 24.7337L15.6235 17.712C15.5268 17.4045 15.4456 16.9008 15.4395 16.5728L16.3972 12.9619C16.5971 13.1249 16.8313 13.2965 17.0044 13.3637C17.8479 13.6903 20.3877 14.2278 20.5142 14.2545C20.5691 14.2665 20.6245 14.2724 20.679 14.2724H20.6792C20.9342 14.2724 21.1667 14.142 21.3335 13.9049C21.463 13.7211 21.5501 13.4749 21.5786 13.2113C21.6138 12.8896 21.5611 12.5696 21.4304 12.3103L21.4303 12.31Z"
                    fill="#CCCCCC"/>
                <path
                    d="M15.4446 7.64046C16.7239 7.64046 17.7648 6.59952 17.7648 5.32023C17.7648 4.04094 16.7239 3 15.4446 3C14.1653 3 13.1244 4.04094 13.1244 5.32023C13.1244 6.59952 14.1653 7.64046 15.4446 7.64046Z"
                    fill="#CCCCCC"/>
                <path
                    d="M11.0099 17.7954L10.4857 19.6147C10.3929 19.9366 10.1738 20.4505 10.0037 20.7464L7.13763 24.7504C7.13194 24.7583 7.12668 24.7666 7.12165 24.7752C6.97566 25.0258 6.96034 25.2884 7.07963 25.4959C7.19891 25.7034 7.43354 25.8222 7.72353 25.8222H8.81546C9.27552 25.8222 9.83802 25.5287 10.1055 25.151L12.7558 22.054C12.7632 22.0455 12.77 22.0365 12.7763 22.0271C12.9289 21.804 13.0898 21.4757 13.2064 21.1641L11.221 18.1881C11.1442 18.0728 11.0731 17.9393 11.0098 17.7955L11.0099 17.7954Z"
                    fill="#CCCCCC"/>
            </svg>
        ),
        cName: 'nav-text'
    },
    {
        title: 'Board',
        path: '/board',
        icon: (
            <svg className="nav-icon"
                 viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M15.1799 2.91C15.1799 1.58 16.2599 0.5 17.5899 0.5C18.9199 0.5 19.9999 1.58 19.9999 2.91C19.9999 4.24 18.9199 5.32 17.5899 5.32C16.2599 5.32 15.1799 4.24 15.1799 2.91ZM11.3298 13.2593L14.2198 9.5303L14.1798 9.5503C14.3398 9.3303 14.3698 9.0503 14.2598 8.8003C14.1508 8.5503 13.9098 8.3803 13.6508 8.3603C13.3798 8.3303 13.1108 8.4503 12.9498 8.6703L10.5308 11.8003L7.75976 9.6203C7.58976 9.4903 7.38976 9.4393 7.18976 9.4603C6.99076 9.4903 6.81076 9.5993 6.68976 9.7593L3.73076 13.6103L3.66976 13.7003C3.49976 14.0193 3.57976 14.4293 3.87976 14.6503C4.01976 14.7403 4.16976 14.8003 4.33976 14.8003C4.57076 14.8103 4.78976 14.6893 4.92976 14.5003L7.43976 11.2693L10.2898 13.4103L10.3798 13.4693C10.6998 13.6393 11.0998 13.5603 11.3298 13.2593ZM13.4498 2.2803C13.4098 2.5303 13.3898 2.7803 13.3898 3.0303C13.3898 5.2803 15.2098 7.0993 17.4498 7.0993C17.6998 7.0993 17.9398 7.0703 18.1898 7.0303V15.0993C18.1898 18.4903 16.1898 20.5003 12.7898 20.5003H5.40076C1.99976 20.5003 -0.000244141 18.4903 -0.000244141 15.0993V7.7003C-0.000244141 4.3003 1.99976 2.2803 5.40076 2.2803H13.4498Z"
                      fill="#CCCCCC"/>
            </svg>

        ),
        cName: 'nav-text'
    },
    {
        title: 'Tasks',
        path: '/tasks',
        icon: (
            <svg className="nav-icon"
                 viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M0 5C0 2.51472 2.01472 0.5 4.5 0.5H14.5C15.3284 0.5 16 1.17157 16 2V17C16 17.5523 15.5523 18 15 18H3.5C1.73676 18 0.278059 16.6961 0.0354444 15H0V5ZM14.5 12.5H3.5C2.39543 12.5 1.5 13.3954 1.5 14.5C1.5 15.6046 2.39543 16.5 3.5 16.5H14.5V12.5ZM4.25 5C4.25 4.58579 4.58579 4.25 5 4.25H12C12.4142 4.25 12.75 4.58579 12.75 5C12.75 5.41421 12.4142 5.75 12 5.75H5C4.58579 5.75 4.25 5.41421 4.25 5ZM5 7.25C4.58579 7.25 4.25 7.58579 4.25 8C4.25 8.41421 4.58579 8.75 5 8.75H10C10.4142 8.75 10.75 8.41421 10.75 8C10.75 7.58579 10.4142 7.25 10 7.25H5Z"
                      fill="#CCCCCC"/>
            </svg>
        ),
        cName: 'nav-text'
    }
];

const NavBar = () => {
    const [impactOccurred] = useHapticFeedback();

    return (
        <nav className='nav-menu'>
            <ul className='nav-menu-items'>
                {NavBarRouter.map((item, index) => (
                    <li key={index} className={item.cName}>
                        <NavLink
                            onClick={() => {
                                impactOccurred('light');
                            }}
                            to={item.path}
                            className={({isActive}) => isActive ? 'nav-text active' : 'nav-text'}>
                            {item.icon}
                            <span>{item.title}</span>
                        </NavLink>
                    </li>
                ))}
            </ul>
        </nav>
    );
};

export default NavBar;
