import axios from "axios";
import * as constants from "../constants/constants";

const axiosInstance = axios.create({
    baseURL: constants.BACKEND_URL,
    headers: {
        'Content-Type': 'application/json'
    }
});

axiosInstance.interceptors.request.use(config => {
    const jwtToken = localStorage.getItem('jwtToken');

    if (jwtToken) {
        config.headers.Authorization = `Bearer ${jwtToken}`;
    }

    return config;
}, error => {
    return Promise.reject(error);
});

export const getUserByTelegramId = (tg_id) => {
    return axiosInstance.post('/login', {tg_id: tg_id})
        .then(response => response.data)
        .catch(error => {
            console.error("Failed to authorize by Telegram id: " + error);
            throw error;
        });
}

export const initializeUserByTgId = (tg_id, selected_team, points) => {
    return axiosInstance.post('/initializeUser', {
        tg_id: tg_id,
        selected_team: selected_team,
        points: points
    }).then(response => response.data)
        .catch(error => {
            console.error("Failed to initialize user: " + error);
            throw error;
        });
}

export const getFriendsLeaderBoard = (tg_id) => {
    return axiosInstance.post('/getFriends', {tg_id: tg_id})
        .then(response => response.data)
        .catch(error => {
            console.error("Failed to get team: " + error);
            throw error;
        });
}

export const getUsers = () => {
    return axiosInstance.post('/getTopPointsUsers')
        .then(response => response.data)
        .catch(error => {
            console.error("Failed to get users: " + error);
            throw error;
        });
}

export const getTasks = (tg_id) => {
    return axiosInstance.post('/getUserTasks', {tg_id: tg_id})
        .then(response => response.data)
        .catch(error => {
            console.error("Failed to get tasks: " + error);
            throw error;
        });
}

export const getTeamVotes = () => {
    return axiosInstance.post('/getVotes')
        .then(response => response.data)
        .catch(error => {
            console.error("Failed to get team: " + error);
            throw error;
        });
}

export const collectVotes = (task_id, tg_id) => {
    return axiosInstance.post('/tasksScore', {task_id: task_id, tg_id: tg_id})
        .then(response => response.data)
        .catch(err => {
            console.error("Failed to collect votes: " + err);
            throw err;
        });
}

export const startVote = (tg_id) => {
    return axiosInstance.post('/startVote', {tg_id: tg_id})
        .then(response => {
            return response.data.vote.vote_start_time
        })
        .catch(err => {
            console.error("Failed to start vote: " + err);
            throw err;
        });
}

export const stopVote = (tg_id) => {
    return axiosInstance.post('/stopVote', {tg_id: tg_id})
        .then(response => {
            return response.data.vote.vote_start_time
        })
        .catch(err => {
            console.error("Failed to start vote: " + err);
            throw err;
        });
}

export const taskExecutingCheck = (task_id, tg_id) => {
    return axiosInstance.post('/taskExecutingCheck', {task_id: task_id, tg_id})
        .then(response => {
            return response.data.result;
        })
        .catch(err => {
            console.error("Failed to execute task: " + err);
            throw err;
        });
}